<template>
    <div class="modal-profile-item-selector edit-profile-detail">
        <div class="title" v-html="title" />
        <div v-if="showInput" class="input-wrapper items-center">
            <!-- <img class="m-r-4" :src="require('@/assets/images/icon-search.png')" alt="" /> -->
            <input
                ref="searchValue"
                v-model="searchValue"
                @input="onKeydown"
                :placeholder="$translate(`PLACEHOLDER_SEARCH_${placeholder.toUpperCase()}`)"
            />
            <i v-if="searchValue" @click="onClickCancel" class="material-icons">cancel</i>
        </div>
        <div class="no-search-result m-l-20" v-if="noResult">
            <i class="material-icons-outlined m-r-4">info</i>
            <span v-html="model === 'state' ? $translate('NO_SEARCH_RESULT_STATE') : $translate('NO_SEARCH_RESULT')" />
        </div>
        <div class="write-university-name m-l-20 flex-wrap" v-else>
            <span v-if="options.model === 'university'">해외 대학의 경우 영문으로 적어주세요</span>
        </div>
        <ul class="result flex-fill" :class="{ 'grid-col': displayGrid }">
            <li
                @click="onClickItem(item)"
                class="item flex-row items-center flex-between"
                :class="{ selected: item.$$selected }"
                style="padding-left: 16px"
                :key="item.id"
                v-for="item in profileItems"
            >
                <div style="margin: 16px 0" v-html="$translate(getVal(item))" />
            </li>
        </ul>
    </div>
</template>

<script>
import profileService from '@/services/profile'
import debounce from '@/modules/debounce'
// import OnlyToManagerVue from '@/routes/user-detail/components/OnlyToManager.vue'

export default {
    name: 'ItemSteps',
    props: ['options', 'onlyChevronLeft', 'isEditing', 'stage'],
    data: () => ({
        nowStep: 0,
        noResult: false,
        selected: [],
        profileItems: [],
        searchValue: '',
    }),
    async mounted() {
        this.init()
    },
    watch: {
        async stage() {
            // await this.editing()
            // await this.initProfileOptions()
            // this.selected = []
            this.init()
        },
        selected: {
            handler() {
                if (this.selected.length > this.nowStep) {
                    this.$emit('disabled', false)
                } else {
                    this.$emit('disabled', true)
                }
            },
            deep: true,
        },
    },
    computed: {
        section() {
            return this.$sections()
        },
        displayGrid() {
            if (this.nowStep > 0) return false

            return ['state', 'hometown'].includes(this.model)
        },
        findCategory() {
            if (this.model === 'university') {
                return 'Education'
            }
            let res = ''
            for (let i = 0; i < this.section.length; i++) {
                if (this.section[i].column.includes(this.model)) {
                    res = this.section[i].title
                    break
                }
            }

            return res
        },
        title() {
            if (this.model === 'university') {
                const schoolDegree = this.$translate(this.schoolDegree.toUpperCase())
                const backString = this.nowStep ? this.$translate(`SCHOOL_STEP_${this.nowStep}`) : ''

                return `${schoolDegree}${backString}`
            } else if (this.model === 'state') {
                if (this.options.title === 'company_state') {
                    if (this.nowStep === 0) return '직장소재지'
                    else {
                        if (this.isStation) return '직장에서 가까운 지하철역'
                        else return '시/군/구'
                    }
                }
                if (this.stateType === 'state') {
                    if (this.nowStep === 0) return '지역'
                    else {
                        if (this.isStation) return '집에서 가까운 지하철역'
                        else return '시/군/구'
                    }
                } else {
                    return '본가 지역'
                }
            } else {
                if (this.nowStep === 0) return '보유 차량'
                else if (this.nowStep === 1) return '차량 브랜드'
                else return '차종'
            }
        },
        isStation() {
            if (this.model === 'state') {
                return this.selected.some(s => s.is_station === true)
            }
            return false
        },
        placeholder() {
            if (this.model === 'state') {
                return this.isStation ? 'station' : 'region'
            } else if (this.model === 'university') {
                return this.nowStep === 0 ? 'university' : this.nowStep === 1 ? 'college' : 'major'
            }
            return this.model
        },
        model() {
            return this.options.model
        },
        lastStep() {
            if (this.model === 'university') return 2

            return 1
        },
        schoolDegree() {
            if (this.model !== 'university') return ''

            return this.options.schoolDegree || 'bachelor'
        },
        stateType() {
            // state || hometown
            if (this.model !== 'state') return ''

            return this.options.stateType || 'state'
        },
        showInput() {
            if (this.model === 'university') return true

            if (this.model === 'state') {
                if (this.nowStep) {
                    return this.isStation
                }
            }

            if (this.model === 'car') {
                return this.nowStep === 1
            }

            return false
        },
        profile() {
            return this.$store.getters.profile
        },
    },
    methods: {
        async init() {
            await this.initProfileOptions()
            await this.editing()
        },
        onClickCancel() {
            this.searchValue = ''
            this.search('')
        },
        async initProfileOptions() {
            if (this.model === 'university') {
                this.profileItems = await profileService.universities({ schoolTypeId: 7, name: '' })
            } else if (this.model === 'state') {
                this.profileItems = this.$store.getters.states
            } else if (this.model === 'car') {
                this.profileItems = this.$store.getters.carType
            }
        },
        editing() {
            if (this.model === 'state') {
                console.log('1')

                if ((this.options.title || '').includes('company')) {
                    //  직장 소재지인 경우
                    console.log('2')
                    if (this.nowStep === 0 && this.profile.company[0].company_state_id) {
                        console.log('2-1')
                        this.profileItems.forEach(item => {
                            if (item.id === this.profile.company[0].company_state_id) {
                                this.$set(item, '$$selected', true)
                                this.selected = [...this.selected, item]
                            } else {
                                this.$set(item, '$$selected', false)
                            }
                        })
                    } else if (this.nowStep === 1 && this.profile.company[0].company_station_id) {
                        console.log('2-2')
                        this.profileItems.forEach(item => {
                            if (item.id === this.profile.company[0].company_station_id) {
                                this.$set(item, '$$selected', true)
                                this.selected = [...this.selected, item]
                            } else {
                                this.$set(item, '$$selected', false)
                            }
                        })
                    } else if (this.nowStep === 1 && this.profile.company[0].company_region_id) {
                        console.log('2-3')
                        this.profileItems.forEach(item => {
                            if (item.id === this.profile.company[0].company_region_id) {
                                this.$set(item, '$$selected', true)
                                this.selected = [...this.selected, item]
                            } else {
                                this.$set(item, '$$selected', false)
                            }
                        })
                    } else {
                        this.profileItems.forEach(item => {
                            this.$set(item, '$$selected', false)
                        })
                    }
                } else {
                    console.log('3')
                    //  일반 지역의 경우
                    if (this.nowStep === 0 && this.profile.state) {
                        this.profileItems.forEach(item => {
                            if (item.id === this.profile.state.id) {
                                this.$set(item, '$$selected', true)
                                this.selected = [...this.selected, item]
                            } else {
                                this.$set(item, '$$selected', false)
                            }
                        })
                    } else if (this.nowStep === 1 && this.profile.station) {
                        this.profileItems.forEach(item => {
                            if (item.id === this.profile.station.id) {
                                this.$set(item, '$$selected', true)
                                this.selected = [...this.selected, item]
                            } else {
                                this.$set(item, '$$selected', false)
                            }
                        })
                    } else if (this.nowStep === 1 && this.profile.region) {
                        this.profileItems.forEach(item => {
                            if (item.id === this.profile.region.id) {
                                this.$set(item, '$$selected', true)
                                this.selected = [...this.selected, item]
                            } else {
                                this.$set(item, '$$selected', false)
                            }
                        })
                    }
                }
            }
        },
        async onClickItem(item) {
            this.noResult = false
            this.searchValue = ''

            this.$set(item, '$$selected', !item.$$selected)

            setTimeout(async () => {
                this.$set(item, '$$selected', !item.$$selected)
                const dom = document.querySelector('.modal-profile-item-selector')
                dom.scrollTo(0, 0)

                if (this.model === 'car') {
                    console.log('dddd', item)

                    this.selected = [...this.selected, item]

                    this.$emit('close', this.selected)
                    return
                }

                if (this.nowStep < this.lastStep) {
                    if (this.selected[this.nowStep]) {
                        this.selected.splice(this.nowStep, 1, item)
                    } else {
                        this.selected = [...this.selected, item]
                    }
                    if (this.model === 'university') {
                        if (!this.nowStep) this.profileItems = await profileService.colleges({ name: '' })
                        else this.profileItems = await profileService.majors({ name: '' })
                    } else if (this.model === 'state') {
                        if (this.isStation) {
                            this.profileItems = await profileService.stations({ region: item.name, name: '' })
                        } else {
                            this.profileItems = await profileService.regions(item)
                        }
                        // } else if (this.model === 'car') {
                        // if (!this.nowStep) this.profileItems = this.$store.getters.carBrand
                        // else this.profileItems = []
                        // this.profileItems = []
                    }
                    this.nowStep += 1
                } else {
                    this.$set(item, '$$selected', !item.$$selected)
                    this.selected = [...this.selected, item]
                    this.$emit('disabled', false)
                    this.$emit('close', this.selected)
                }
            }, 200)
        },
        async onSave(key) {
            // 뒤로가기 버튼 클릭 시 동작
            if (!this.selected.length) return true

            if (key === 'state' || key === 'company_state') {
                if (this.nowStep === 0) {
                    if (this.selected[0].is_station === true) {
                        this.profileItems = await profileService.stations({ region: this.selected[0].name, name: '' })
                    } else {
                        this.profileItems = await profileService.regions(this.selected[0])
                    }
                    this.nowStep++
                    await this.editing()
                    return true
                }
                if (this.selected.length < 2) return true

                const isCompany = this.options.title.includes('company') ? true : false

                const prefix = isCompany ? 'company_' : ''
                const region = {}
                const company = this.profile.company
                const item = this.selected

                if (isCompany) {
                    if (item[0].is_station) {
                        company[0][`${prefix}state_name`] = item[0].name
                        company[0][`${prefix}state_id`] = item[0].id
                        company[0][`${prefix}station_name`] = item[1].name
                        company[0][`${prefix}station_id`] = item[1].id
                    } else {
                        company[0][`${prefix}state_name`] = item[0].name
                        company[0][`${prefix}state_id`] = item[0].id
                        company[0][`${prefix}region_name`] = item[1].district
                        company[0][`${prefix}region_id`] = item[1].id
                    }

                    company[0].show_region_shorten = 1
                    this.$set(this.profile, 'company', company)
                } else {
                    if (item[0].is_station) {
                        region[`${prefix}state`] = item[0]
                        region[`${prefix}state_id`] = item[0].id
                        region[`${prefix}station`] = item[1]
                        region[`${prefix}station_id`] = item[1].id
                    } else {
                        region[`${prefix}state`] = item[0]
                        region[`${prefix}state_id`] = item[0].id
                        region[`${prefix}region`] = item[1]
                        region[`${prefix}region_id`] = item[1].id
                    }
                    this.$store.commit('setProfile', { ...this.$store.getters.profile, ...region })
                }
            } else if (key === 'car') {
                this.$profile.updateProfileOptions('car_type', this.selected[0])
            }
        },
        onKeydown() {
            setTimeout(() => {
                this.searchValue = this.$refs.searchValue.value
                this.search(this.searchValue)
            })
        },
        search: debounce(async function (val) {
            if (this.model === 'university') {
                if (this.nowStep === 0) {
                    this.profileItems = await profileService.universities({ schoolTypeId: 7, name: val })
                } else if (this.nowStep === 1) {
                    this.profileItems = await profileService.colleges({ name: val })
                } else {
                    this.profileItems = await profileService.majors({ name: val })
                }
            } else if (this.model === 'state') {
                const region = this.selected[0].name
                this.profileItems = await profileService.stations({ region, name: val })
            } else if (this.model === 'car') {
                this.profileItems = [{ name: this.searchValue }]
            }

            if (!this.profileItems.length) {
                this.noResult = true
                this.profileItems = this.model === 'state' ? [] : [{ name: this.searchValue }]
            } else {
                this.noResult = false
            }
        }, 200),
        getVal(o) {
            if (['university', 'car'].includes(this.model)) {
                if (this.noResult) return `${o.name} 로 입력하기`
            }
            return this.options.model === 'state' && this.nowStep && !this.isStation ? o.district : o.name
        },
    },
}
</script>
